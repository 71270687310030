<svg
  aria-hidden="true"
  width="8"
  height="12"
  viewBox="0 0 8 12"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  style="flex-shrink: 0"
  class="right-arrow"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M0.317383 10.7574L1.56004 12L7.56006 6.00002L1.56004 0L0.317383 1.24265L5.07475 6.00002L0.317383 10.7574Z"
    fill="currentColor"
  />
</svg>
