<script>
  import Skeleton from "svelte-skeleton/dist/Skeleton.svelte";
</script>

<div class="slider">
  <div class="slider-slider">
    <div class="slider-tabs">
      <div class="slider-tab">
        <Skeleton
          primaryColor="#E0E0E0"
          secondaryColor="#ebecef"
          height="119"
          width="100%"
        >
          <rect width="55" height="20" x="0" y="20" rx="5" ry="5" />
          <rect width="302" height="55" x="0" y="48" rx="5" ry="5" />
        </Skeleton>
      </div>
      <div class="slider-tab" style="">
        <Skeleton
          primaryColor="#E0E0E0"
          secondaryColor="#ebecef"
          height="119"
          width="100%"
        >
          <rect width="55" height="20" x="0" y="20" rx="5" ry="5" />
          <rect width="302" height="55" x="0" y="48" rx="5" ry="5" />
        </Skeleton>
      </div>
      <div class="slider-tab">
        <Skeleton
          primaryColor="#E0E0E0"
          secondaryColor="#ebecef"
          height="119"
          width="100%"
        >
          <rect width="55" height="20" x="0" y="20" rx="5" ry="5" />
          <rect width="302" height="55" x="0" y="48" rx="5" ry="5" />
        </Skeleton>
      </div>
      <div class="slider-tab">
        <Skeleton
          primaryColor="#E0E0E0"
          secondaryColor="#ebecef"
          height="119"
          width="100%"
        >
          <rect width="55" height="20" x="0" y="20" rx="5" ry="5" />
          <rect width="302" height="55" x="0" y="48" rx="5" ry="5" />
        </Skeleton>
      </div>
      <div class="slider-tab">
        <Skeleton
          primaryColor="#E0E0E0"
          secondaryColor="#ebecef"
          height="119"
          width="100%"
        >
          <rect width="55" height="20" x="0" y="20" rx="5" ry="5" />
          <rect width="302" height="55" x="0" y="48" rx="5" ry="5" />
        </Skeleton>
      </div>
    </div>
    <div class="slider-arrow slider-arrow-prev">
      <Skeleton
        primaryColor="#E0E0E0"
        secondaryColor="#ebecef"
        height="20"
        width="20"
      >
        <rect width="20" height="20" x="0" y="0" rx="5" ry="5" />
      </Skeleton>
    </div>
    <div class="slider-arrow slider-arrow-next">
      <Skeleton
        primaryColor="#E0E0E0"
        secondaryColor="#ebecef"
        height="20"
        width="20"
      >
        <rect width="20" height="20" x="0" y="0" rx="5" ry="5" />
      </Skeleton>
    </div>
    <div class="slide">
      <Skeleton
        primaryColor="#E0E0E0"
        secondaryColor="#ebecef"
        height="185"
        width="100%"
      >
        <rect width="54" height="24" x="0" y="0" rx="5" ry="5" />
        <rect width="284" height="32" x="0" y="31" rx="5" ry="5" />
        <rect width="100%" height="48" x="0" y="83" rx="5" ry="5" />
        <rect width="130" height="24" x="0" y="161" rx="5" ry="5" />
      </Skeleton>
    </div>
  </div>
</div>

<style type="text/css">
  .slider {
    width: 100%;
    padding-right: 367px;
    position: relative;
  }

  .slider,
  .slider-slider,
  .slider-tabs,
  .slide {
    min-height: 640px;
  }

  .slider-tabs {
    position: absolute;
    top: 0;
    right: 0;
    width: 352px;
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    flex-shrink: 0;
  }

  .slider-tab {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
    padding: 0 25px;
    justify-content: center;
    font-family: "Segoe UI", sans-serif;
    background: #f6f6f6;
    position: relative;
  }

  .slider-tab:first-of-type:before {
    position: absolute;
    top: 50%;
    left: -20px;
    margin-top: -10px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid transparent;
    border-right: 10px solid #f6f6f6;
    content: " ";
  }

  .slide {
    padding: 30px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
  }

  .slide,
  .slider-tab,
  .slider-arrow {
    background: #f8f8f9;
  }

  .slider-arrow {
    display: none;
  }

  @media screen and (max-width: 1050px) {
    .slider {
      padding-right: 317px;
    }

    .slider-tabs {
      width: 302px;
    }
  }

  @media screen and (max-width: 850px) {
    .slider {
      padding-right: 30px;
      padding-left: 30px;
    }

    .slider-tabs {
      display: none;
    }

    .slide {
      align-items: center;
    }

    .slider-arrow {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: hand;
      cursor: pointer;
      transition: all 150ms cubic-bezier(0.57, 0.21, 0.69, 1.25);
      border-right: 1px solid #e0e0e0;
    }

    .slider-arrow.slider-arrow-next {
      left: auto;
      right: 0;
      border-right: 0;
      border-left: 1px solid #e0e0e0;
    }

    .slider,
    .slider-slider,
    .slider-tabs,
    .slide {
      min-height: 500px;
    }

    @media screen and (max-width: 550px) {
      .slider {
        padding-left: 0;
        padding-right: 0;
      }

      .slider-arrow {
        left: -30px;
      }

      .slider-arrow.slider-arrow-next {
        right: -30px;
      }
    }
  }
</style>
