<script>
  import ArrowLink from "../Common/ArrowLink.svelte";
  import FeaturedLinks from "../FeaturedReports/FeaturedLinks.svelte";
  export let content;
</script>

<div class="slide" style="background-image:url({content.image});">
  <div class="slide-overlay" />
  <div class="slide-content">
    <span class="slide-content-type">{content.typeText}</span>
    <h2 class="slide-content-title">
      <a
        class="slide-content-title-link"
        href={content.type === "custom" ? content.linkUrl : content.url}
      >
        {content.title}
      </a>
    </h2>
    <p class="slide-content-description">
      {content.description}
    </p>
    <div class="slide-content-links">
      {#if content.type === "custom"}
        <ArrowLink text={content.linkLabel} href={content.linkUrl} />
      {:else}
        {#if content.foundSummaryReport}
          <ArrowLink
            text="View Summary"
            href={content.foundSummaryReport.slug}
          />
        {/if}
        {#if content.podcastUrl}
          <ArrowLink text="Video Summary" href={content.podcastUrl} />
        {/if}
        <ArrowLink text="Read Report" href={content.url} newTab="true" />
      {/if}
    </div>
  </div>
</div>

<style type="text/css">
  .slide,
  .slide-content {
    min-height: 640px;
  }

  .slide {
    background-size: cover;
    background-position: 50% 50%;
    position: relative;
  }

  .slide-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.8) 35.16%,
      rgba(0, 0, 0, 0) 60.82%
    );
    z-index: 2;
  }

  .slide-content {
    display: flex;
    padding: 30px;
    flex-direction: column;
    justify-content: flex-end;
    color: #fff;
    font-family: "Segoe UI", sans-serif;
    position: relative;
    z-index: 3;
  }

  .slide-content-type {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 24px;
    line-height: 2.4rem;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin-bottom: 7px;
    color: rgba(255, 255, 255, 0.7);
    text-transform: uppercase;
  }

  .slide-content-title {
    color: #fff;
    font-family: "Segoe UI", sans-serif;
    font-size: 24px;
    font-size: 2.4rem;
    line-height: 32px;
    line-height: 3.2rem;
    font-weight: 700;
    margin: 0;
    padding: 0;
  }

  .slide-content-title-link {
    color: #fff;
    text-decoration: none;
  }

  .slide-content-title-link:hover,
  .slide-content-title-link:focus {
    text-decoration: underline;
  }

  .slide-content-description {
    margin-top: 20px;
    padding: 0;
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 24px;
    line-height: 2.4rem;
  }

  .slide-content-links {
    display: flex;
    gap: 40px;
    margin-top: 30px;
  }

  .slide-content-links :global(a) {
    color: #fff !important;
    transition: all 150ms cubic-bezier(0.57, 0.21, 0.69, 1.25);
  }

  .slide-content-links :global(a:hover),
  .slide-content-links :global(a:focus) {
    color: rgba(255, 255, 255, 0.7) !important;
  }

  @media screen and (max-width: 850px) {
    .slide,
    .slide-content {
      min-height: 500px;
    }

    .slide-content {
      justify-content: center;
    }

    .slide-overlay {
      background: rgba(0, 0, 0, 0.7);
    }
  }

  @media screen and (max-width: 550px) {
    .slide-content-links {
      flex-direction: column;
      gap: 15px;
    }
  }
</style>
