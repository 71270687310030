//? polyfill fetch since browser apis are not ECMAScript, they aren't included in core-js polyfills
import "whatwg-fetch";

import HomeWidget from "./HomeWidget.svelte";
const widget = new HomeWidget({
  target: document.getElementById("reports-widget"),
  props: {},
});

export default widget;
