/**
 * Decode Param
 *
 * Decodes url-encoded parameters into the desired type or passes through as
 * null when the parameter doesn't exist.
 *
 * @param {object} options
 * @param {?string} options.param
 * @param {('string'|'strings'|'number'|'numbers')} options.type
 *
 * @returns {string|string[]|number|number[]|null}
 */
function decodeParam({ param, type }) {
  if (!param) return null;

  switch (type) {
    case "string":
      return decodeURIComponent(param);
    case "strings":
      return decodeURIComponent(param).split(",");
    case "number":
      return parseInt(decodeURIComponent(param));
    case "numbers":
      return decodeURIComponent(param)
        .split(",")
        .map((string) => parseInt(string));
  }
}

export default decodeParam;
