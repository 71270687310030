import isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import { detect } from "detect-browser";
import { writable } from "svelte/store";
import decodeParam from "./helpers/decodeParam";
import reportsFetch from "./helpers/reportsFetch";

/**
 * Feature Flags (technically not a store)
 */
export const INCLUDE_SECTORS = WP.INCLUDE_SECTORS === "true" || false;
export const browser = detect();

/**
 * Modal State
 */
export const modal = writable(null);

/**
 * App State
 */
export const appState = writable({
  loading: true,
  currentPage: null,
  numberOfResults: null,
  reports: null,
  updatedFilters: null,
});

/**
 * Query State
 */
// setup
const isSearchPage = () => location.pathname.includes("/search");
const urlParams = new URLSearchParams(location.search);
const searchParam = decodeParam({
  param: urlParams.get("search"),
  type: "string",
});
let sortParam = decodeParam({ param: urlParams.get("sort"), type: "string" });

if (isSearchPage() && !searchParam && !sortParam) {
  urlParams.set("sort", "date");
  sortParam = "date";
}

const initialQueryState = {
  search: searchParam,
  sectorIds: decodeParam({
    param: urlParams.get("sectorIds"),
    type: "numbers",
  }),
  reportTypes: decodeParam({
    param: urlParams.get("reportTypes"),
    type: "numbers",
  }),
  publishedYears: decodeParam({
    param: urlParams.get("publishedYears"),
    type: "numbers",
  }),
  fiscalYears: decodeParam({
    param: urlParams.get("fiscalYears"),
    type: "numbers",
  }),
  organizations: decodeParam({
    param: urlParams.get("organizations"),
    type: "strings",
  }),
  sort: sortParam,
  pg: decodeParam({ param: urlParams.get("pg"), type: "number" }),
};

// create store
export const queryState = writable(initialQueryState);

// also save to history state
// ? this will allow us to:
// * 1) detect if we are initializing or reacting to subsequent changes
// * 2) keep state updated when moving back/fwd through history
if (isSearchPage()) {
  history.replaceState(
    initialQueryState,
    document.title,
    `${location.href.split("?")[0]}?${urlParams}` //? add urlParams manually in case sort param was manipulated
  );
}

// subscribe to changes:
// * sync history state
// * fetch
queryState.subscribe((queryState) => {
  // translate state object into a query string
  let newParams = new URLSearchParams(
    Object.entries(queryState || {}).filter((param) => param[1])
  );
  newParams = newParams.toString().length ? "?" + newParams : "";
  const targetLocation = newParams || location.pathname;

  //! subsequent changes only, not on init
  const shouldUpdate =
    history.state !== null && !isEqual(omit(history.state, "key"), queryState);
  if (shouldUpdate) {
    // for IE, just short-circuit this process to a page load with the target state
    if (browser.name === "ie") {
      location = targetLocation;
      return;
    }

    // sync to history
    history.pushState(queryState, document.title, targetLocation);
  }

  //! on init as well as all subsequent changes
  // fetch new results (search page only)
  if (isSearchPage()) {
    appState.update((appState) => ({ ...appState, loading: true }));
    reportsFetch("search", newParams).then((data) => {
      appState.update(() => ({ ...data, loading: false }));
    });
  }
});

// Update queryState on back/fwd
window.onpopstate = function (event) {
  if (event.state) queryState.update(() => omit(event.state, "key"));
};
