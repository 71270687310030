<script>
  import { Circle } from "svelte-loading-spinners";
  import SearchIcon from "../Common/Icons/SearchIcon.svelte";

  // Props
  export let sectors, organizations, loading;

  // State
  let search = "",
    sectorId = "",
    organizationId = "";

  function handleSubmit(search, sectorId, organizationId) {
    const urlParams = new URLSearchParams();

    if (search) urlParams.set("search", encodeURIComponent(search));
    if (!search) urlParams.set("sort", "date");
    if (sectorId) urlParams.set("sectorIds", encodeURIComponent([sectorId]));
    if (organizationId)
      urlParams.set("organizations", encodeURIComponent([organizationId]));

    const destination = urlParams.toString().length
      ? `/reports/search?${urlParams}`
      : "/reports/search";

    location.replace(destination);
  }
</script>

<div id="reports-search-form">
  <div class="container">
    <form
      on:submit|preventDefault={() =>
        handleSubmit(search, sectorId, organizationId)}
    >
      <div class="search-input-wrap">
        <div id="search-icon">
          <SearchIcon />
        </div>
        <input
          type="text"
          placeholder="Search reports..."
          bind:value={search}
        />
        <div class="search-empty">
          or <a href="/reports/search/?sort=date">view all reports</a>
        </div>
      </div>

      <div class="select">
        <select bind:value={sectorId} disabled={loading}>
          <option value="">All Sectors</option>

          {#each sectors as sector}
            <option value={sector.id}>{sector.title}</option>
          {/each}
        </select>
      </div>

      {#if loading}
        <div class="loader-wrap">
          <span class="loader-inner">
            <Circle size="20" color="#fff" unit="px" />
          </span>
        </div>
      {/if}

      <div class="select">
        <select bind:value={organizationId} disabled={loading}>
          <option value="">All Organizations</option>
          {#each organizations as organization}
            <option value={organization.id}>{organization.name}</option>
          {/each}
        </select>
      </div>
      {#if loading}
        <div class="loader-wrap">
          <span class="loader-inner">
            <Circle size="20" color="#fff" unit="px" />
          </span>
        </div>
      {/if}

      {#if loading}
        <div class="loader-wrap">
          <span class="loader-inner">
            <Circle size="20" color="#fff" unit="px" />
          </span>
        </div>
      {/if}

      <button>Go</button>
    </form>
  </div>
</div>

<style>
  #reports-search-form {
    padding: 60px 0 40px;
    background-color: #4a4a4a;
  }

  form {
    display: flex;
    align-items: flex-start;
  }

  .search-input-wrap {
    position: relative;
    flex-basis: 420px;
    min-width: 280px;
  }

  #search-icon {
    color: #a8a4a1;
    width: 20px;
    height: 20px;
    background-color: #ffffff;
    position: absolute;
    left: 15px;
    top: 21px;

    display: flex;
    align-items: center;
  }

  .search-empty {
    color: #aaa;
    padding: 12px 0 0 50px;
    font-size: 13px;
    line-height: 15px;
  }

  .search-empty a {
    color: #aaa;
    text-decoration: underline;
  }

  .search-empty a:hover,
  .search-empty a:focus {
    color: #fff;
  }

  #reports-search-form input {
    width: 100%;
    min-height: 65px;
    background: #ffffff;
    border-radius: 10px;
    border: none;
    padding-left: 50px;
    color: #4a4a4a;
    font-family: "Segoe UI", sans-serif;
    margin: 0;
  }

  .select {
    margin-left: 48px;
    flex-basis: 303px;
    max-width: calc((100% - (48px * 3) - 80px - 280px) / 2);
  }

  select {
    height: 65px;
    width: 100%;
  }

  button {
    flex-basis: 186px;
    min-width: 80px;

    min-height: 65px;
    background: #d07743;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    color: #ffffff;
    font-family: "Segoe UI", sans-serif;
    margin-left: 48px;
  }

  .loader-wrap {
    position: relative;
    display: inline-flex;
    align-items: center;
  }
  .loader-inner {
    position: absolute;
    left: 8px;
    top: 22px;
  }

  @media screen and (max-width: 1200px) {
    form {
      display: grid;
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
      grid-gap: 48px;
      row-gap: 30px;
    }
    .select,
    button {
      margin: 0;
    }
    .select {
      max-width: unset;
    }
  }
  @media screen and (max-width: 740px) {
    form {
      grid-template-columns: minmax(0, 1fr);
      grid-gap: 30px;
    }
  }
</style>
