<script>
  import { browser } from "./stores";
  import Slider from "./components/Sliders/Slider.svelte";
  import DynamicSecsAndOrgs from "./helpers/DynamicSecsAndOrgs.svelte";
  import SearchForm from "./components/Reports/SearchForm.svelte";
  import SearchForm__IE from "./components/Reports/SearchForm__IE.svelte";
</script>

<DynamicSecsAndOrgs let:sectors let:organizations let:loading>
  <Slider sliderType="home" />
  {#if browser.name === "ie"}
    <SearchForm__IE {sectors} {organizations} {loading} />
  {:else}
    <SearchForm {sectors} {organizations} {loading} useHttp={true} />
  {/if}
</DynamicSecsAndOrgs>
