<svg
  width="20"
  height="21"
  viewBox="0 0 20 21"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M19.0549 19.5234L13.4267 13.2558C14.695 11.9011 15.3915 10.1302 15.3915 8.25C15.3915 6.31346 14.6528 4.49284 13.3113 3.12346C11.9699 1.75409 10.1863 1 8.28927 1C6.3922 1 4.60867 1.75412 3.2672 3.12346C1.92574 4.49281 1.18701 6.31346 1.18701 8.25C1.18701 10.1865 1.92577 12.0072 3.2672 13.3765C4.60864 14.7459 6.3922 15.5 8.28927 15.5C9.92356 15.5 11.4733 14.9399 12.729 13.9094L18.3569 20.1766C18.4503 20.2806 18.578 20.3333 18.706 20.3333C18.8203 20.3333 18.935 20.2913 19.0259 20.2063C19.2186 20.0259 19.2317 19.7202 19.0549 19.5234ZM2.13398 8.25C2.13398 4.78538 4.89525 1.96667 8.28927 1.96667C11.6833 1.96667 14.4446 4.78538 14.4446 8.25C14.4446 11.7146 11.6833 14.5333 8.28927 14.5333C4.89525 14.5333 2.13398 11.7146 2.13398 8.25Z"
    fill="currentColor"
    stroke="currentColor"
    stroke-width="0.5"
  />
</svg>
