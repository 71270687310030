/**
 * Find a sector by any key, return it or any value from it
 *
 * @param {string} key - the key you want to search by
 * @param {(string|number)} value - the value you will provide for the match
 * @param {?string} output - the value you want to get back, defaults to the whole object
 *
 * @returns {(string|int|object)}
 */
function findSector(key, value, output = "all") {
  const sector = WP.sectorIdMap.find((sector) => sector[key] === value);

  if (output === "all") return sector;

  return sector?.[output];
}

export default findSector;
