import findSector from "./findSector";
import reportsFetch from "./reportsFetch";

async function getDynamicSecsAndOrgs() {
  let sectors = reportsFetch("sectors"),
    organizations = reportsFetch("organizations");

  let results = await Promise.all([sectors, organizations]);

  results[0] = results[0]
    .map((id) => findSector("id", id))
    .filter((id) => !!id); //? no undefined

  return {
    sectors: results[0],
    organizations: results[1],
  };
}

export default getDynamicSecsAndOrgs;
