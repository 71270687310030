/**
 * Fetch from Reports Publisher
 *
 * @param {string} endpoint
 * @param {?URLSearchParams} params
 *
 * @returns {Promise}
 */
async function reportsFetch(endpoint, params = "") {
  const route = `${location.origin}/wp-json/reports/${endpoint}${params}`;
  const defaultError =
    "Looks like something went wrong. Please refresh the page to try your search again.";

  try {
    let response = await fetch(route);

    if (!response.ok) {
      throw defaultError;
    }

    let data = await response.json();

    if (data.errors || !data.body) {
      console.log(data);
      throw defaultError;
    }

    // successful reponses have the real response buried in a JSON string on the response body
    data = JSON.parse(data.body);

    if (endpoint === "search" && !("reports" in data)) throw defaultError;

    return data;
  } catch (error) {
    return { error };
  }
}

export default reportsFetch;
