<script>
  import { link } from "svelte-routing";
  import { browser } from "../../stores";
  import LeftArrow from "./Icons/LeftArrowIcon.svelte";
  import RightArrow from "./Icons/RightArrowIcon.svelte";

  // Props
  export let routing = false;
  export let button = false;
  export let buttonSubmit = null;
  export let reverse = false;
  export let double = false;
  export let href;
  export let text = "";
  export let marginTop = 0;
  export let newTab = false;
  export let stretch = false;

  // State
  let hovering = false;

  // Handlers
  function toggleHover() {
    hovering = !hovering;
  }

  // Conditional styles
  const style = `
    ${marginTop ? ` margin-top: ${marginTop}; ` : ""}
    ${stretch ? " width: 100%; justify-content: space-between; " : ""}
  `;
  let spanStyle = reverse ? "margin-left: 16px;" : "margin-right: 16px;";

  // IE overrides
  if (browser.name === "ie") routing = false;
</script>

{#if routing}
  <a
    {href}
    {style}
    use:link
    on:mouseenter={toggleHover}
    on:mouseleave={toggleHover}
    class:hovered={hovering}
    class="arrow-link"
  >
    {#if reverse}
      <LeftArrow />
      {#if double}
        <LeftArrow />
      {/if}
    {/if}
    <span style={spanStyle}>{text}</span>
    {#if !reverse}
      <RightArrow />
      {#if double}
        <RightArrow />
      {/if}
    {/if}
  </a>
{:else if button && buttonSubmit}
  <button
    on:click|preventDefault={buttonSubmit}
    style={`margin-top: ${marginTop};`}
    on:mouseenter={toggleHover}
    on:mouseleave={toggleHover}
    class:hovered={hovering}
    class="arrow-link"
  >
    {#if reverse}
      <LeftArrow />
      {#if double}
        <LeftArrow />
      {/if}
    {/if}
    <span style={spanStyle}>{text}</span>
    {#if !reverse}
      <RightArrow />
      {#if double}
        <RightArrow />
      {/if}
    {/if}
  </button>
{:else}
  <a
    {href}
    target={newTab ? "_blank" : "_self"}
    {style}
    on:mouseenter={toggleHover}
    on:mouseleave={toggleHover}
    class:hovered={hovering}
    class="arrow-link"
  >
    {#if reverse}
      <LeftArrow />
      {#if double}
        <LeftArrow />
      {/if}
    {/if}
    <span style={spanStyle}>
      {text}
    </span>
    {#if !reverse}
      <RightArrow />
      {#if double}
        <RightArrow />
      {/if}
    {/if}
  </a>
{/if}

<style>
  button {
    border: none;
    background: transparent;
    cursor: pointer;
    color: #006b97;
  }
  button:hover {
    filter: brightness(85%);
  }

  a,
  button {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: inline-flex;
    align-items: center;
    letter-spacing: 0.2px;
    transition: all 150ms cubic-bezier(0.57, 0.21, 0.69, 1.25);
  }

  a :global(.left-arrow),
  a :global(.right-arrow) {
    margin-top: 3px;
  }

  a :global(.left-arrow),
  button :global(.left-arrow),
  a :global(.right-arrow),
  button :global(.right-arrow) {
    transition: transform 150ms cubic-bezier(0.57, 0.21, 0.69, 1.25);
  }

  .hovered :global(.right-arrow) {
    transform: translateX(2px);
  }
  .hovered :global(.left-arrow) {
    /* this is wacky because the svg itself has a style declaration of scale(-1,1) */
    transform: scale(-1, 1) translateX(2px) !important;
  }
</style>
