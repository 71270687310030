<script>
  import getDynamicSecsAndOrgs from "./getDynamicSecsAndOrgs";
  /**
   * HOC to dynamically populate sector and organization data into search form
   * dropdowns, sector browsing grid, etc...
   *
   * Available Sectors and Organizations will change over time so we need to
   * fetch them from the reports publisher API.
   *
   * However, they don't change with high frequency, so rather than re-fetching
   * every time we load a search form, we will cache in session storage and
   * attempt to initialize from cache on load.
   *
   */
  let sectors = [],
    organizations = [],
    loading = true;

  // try to attempt to init from cache
  if (sessionStorage.getItem("sectors")) {
    sectors = JSON.parse(sessionStorage.getItem("sectors"));
  }
  if (sessionStorage.getItem("organizations")) {
    organizations = JSON.parse(sessionStorage.getItem("organizations"));
  }

  // go dynamic if needed
  if (!sectors.length || !organizations.length) {
    getDynamicSecsAndOrgs().then((data) => {
      sectors = data.sectors;
      organizations = data.organizations;

      // and cache
      if (Array.isArray(sectors) && sectors.length)
        sessionStorage.setItem("sectors", JSON.stringify(sectors));
      if (Array.isArray(sectors) && organizations.length)
        sessionStorage.setItem("organizations", JSON.stringify(organizations));
    });
  }

  // loading state
  $: {
    if (sectors.length && organizations.length) loading = false;
  }
</script>

<slot {sectors} {organizations} {loading} />
