<script>
  import ArrowLink from "../Common/ArrowLink.svelte";
  import SliderTab from "./SliderTab.svelte";
  import SliderSlide from "./SliderSlide.svelte";
  import SliderSkeleton from "./SliderSkeleton.svelte";
  import reportsFetch from "../../helpers/reportsFetch";
  import Carousel from "svelte-carousel";
  import MediaQuery from "svelte-media-query";
  import LeftArrow from "../Common/Icons/LeftArrowIcon.svelte";
  import RightArrow from "../Common/Icons/RightArrowIcon.svelte";
  export let sliderType = "home" | "reports";

  // State
  let spotlightReport = reportsFetch("spotlight");
  let featuredReports = reportsFetch("featured");
  let allReports = Promise.all([spotlightReport, featuredReports]);
  let sliderLoading = true;

  // Build slide data
  let sliderSlides = [];
  allReports.then((fetchedReports) => {
    // Get report slides
    let reportSlides = [];
    reportSlides.push(prepareReportSlide(fetchedReports[0][0]));
    fetchedReports[1].forEach(function (report) {
      reportSlides.push(prepareReportSlide(report));
    });

    // Go thru WP items, fill in reports as needed
    if (sliderType === "home") {
      WP.sliderItems.forEach(function (item) {
        if (
          (item.type === "report" || sliderType === "reports") &&
          reportSlides.length !== 0
        ) {
          sliderSlides.push(reportSlides.shift());
        } else if (item.type === "custom") {
          sliderSlides.push(item);
        }
      });
    } else {
      sliderSlides = reportSlides;
    }

    // Load slider
    sliderLoading = false;
  });

  // Format report slide data
  function prepareReportSlide(report) {
    let slide = {
      type: "report",
      typeText: "Report",
      title: report.title,
      description: report.description,
      url: report.url,
      podcastUrl: report.podcastUrl,
    };

    // Summary
    let foundSummaryReport = report?.summaryId
      ? WP.summaryIdMap.find((summary) => {
          return summary.id === report.summaryId;
        })
      : null;
    slide.foundSummaryReport = foundSummaryReport;

    // Image
    let imgSrc = report.featuredImage
      ? `data:image/jpeg;base64,${report.featuredImage}`
      : "";

    // Use sector image if no image available
    const fullImg =
      !imgSrc && report.sectorIds.length
        ? findSector("id", report.sectorIds[0], "spotlightImage")
        : null;
    if (!imgSrc && fullImg) {
      const temp = document.createElement("div");
      temp.innerHTML = fullImg;
      imgSrc = temp.querySelector("img").src;
    }
    slide.image = imgSrc;

    return slide;
  }
</script>

<div class="reports-slider type-{sliderType}">
  <div class="container">
    {#if sliderType === "reports"}
      <h2 class="reports-slider-heading">Featured Reports</h2>
    {/if}

    {#if sliderLoading}
      <SliderSkeleton />
    {:else}
      <div class="slider">
        <div class="slider-slider">
          <MediaQuery query="(min-width: 850px)" let:matches>
            {#if matches}
              <Carousel
                arrows={false}
                dots={true}
                let:currentPageIndex
                let:pagesCount
                let:showPage
              >
                <div slot="dots" class="slider-tabs">
                  {#each Array(pagesCount) as _, pageIndex (pageIndex)}
                    <SliderTab
                      type={sliderSlides[pageIndex].typeText}
                      title={sliderSlides[pageIndex].title}
                      active={currentPageIndex === pageIndex}
                      on:click={() => showPage(pageIndex)}
                    />
                  {/each}
                </div>

                {#each sliderSlides as content}
                  <SliderSlide {content} />
                {/each}
              </Carousel>
            {:else}
              <Carousel
                arrows={true}
                dots={false}
                autoplay
                autoplayDuration={10000}
                autoplayProgressVisible
                pauseOnFocus
                let:showPrevPage
                let:showNextPage
              >
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                <div
                  slot="prev"
                  on:click={showPrevPage}
                  class="slider-arrow slider-arrow-prev"
                >
                  <LeftArrow />
                </div>
                {#each sliderSlides as content}
                  <SliderSlide {content} />
                {/each}
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                <div
                  slot="next"
                  on:click={showNextPage}
                  class="slider-arrow slider-arrow-next"
                >
                  <RightArrow />
                </div>
              </Carousel>
            {/if}
          </MediaQuery>
        </div>
      </div>
    {/if}

    {#if sliderType === "home"}
      <div class="slider-all-link">
        <ArrowLink text="All Reports" href={"/reports/search"} />
      </div>
    {/if}
  </div>
</div>

<style type="text/css">
  .reports-slider {
    padding: 60px 0;
  }

  .reports-slider.type-home {
    padding-top: 20px;
    padding-bottom: 80px;
  }

  .reports-slider-heading {
    margin: 0;
    padding: 0 0 40px;
  }

  .slider {
    width: 100%;
    padding-right: 367px;
    position: relative;
  }

  .slider,
  .slider-slider,
  .slider-tabs {
    min-height: 640px;
  }

  .slider-tabs {
    position: absolute;
    top: 0;
    right: 0;
    width: 352px;
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    flex-shrink: 0;
  }

  .slider-arrow {
    display: flex;
  }

  .slider-all-link {
    padding-top: 35px;
    text-align: right;
  }

  @media screen and (max-width: 1050px) {
    .slider {
      padding-right: 317px;
    }

    .slider-tabs {
      width: 302px;
    }
  }

  @media screen and (max-width: 850px) {
    .slider,
    .slider-slider,
    .slider-tabs {
      min-height: 500px;
    }

    .slider {
      padding-left: 0;
      padding-right: 0;
    }

    :global(.sc-carousel__content-container) {
      padding-left: 30px;
      padding-right: 30px;
    }

    .slider-tabs {
      display: none;
    }

    .slider-arrow {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 30px;
      background: #0e4369;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: hand;
      cursor: pointer;
      transition: all 150ms cubic-bezier(0.57, 0.21, 0.69, 1.25);
    }

    .slider-arrow.slider-arrow-next {
      left: auto;
      right: 0;
    }

    .slider-arrow:hover,
    .slider-arrow:focus {
      background: #00354b;
    }

    .slider-arrow :global(svg) {
      width: 16px;
      height: 16px;
    }

    :global(.sc-carousel-progress__container) {
      background: rgba(255, 255, 255, 0.2) !important;
      z-index: 10;
    }

    :global(.sc-carousel-progress__indicator) {
      background: rgba(255, 255, 255, 0.5) !important;
    }

    @media screen and (max-width: 550px) {
      :global(.sc-carousel__content-container) {
        padding-left: 0;
        padding-right: 0;
      }

      .slider-arrow {
        left: -30px;
      }

      .slider-arrow.slider-arrow-next {
        right: -30px;
      }
    }
  }
</style>
