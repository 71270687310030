<script>
  export let active = false;
  export let type = "";
  export let title = "";
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div class="slider-tab" class:is-active={active} on:click>
  <span class="slider-tab-type">{type}</span>
  <span class="slider-tab-title">{title}</span>
</div>

<style>
  .slider-tab {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
    color: #fff;
    background: #0e4369;
    padding: 0 25px 6px;
    justify-content: center;
    font-family: "Segoe UI", sans-serif;
    cursor: hand;
    cursor: pointer;
    transition: all 150ms cubic-bezier(0.57, 0.21, 0.69, 1.25);
  }

  .slider-tab:not(.is-active):hover,
  .slider-tab:not(.is-active):focus {
    background: #00354b;
  }

  .slider-tab-type {
    display: block;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.7);
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 5px;
    letter-spacing: 0.5px;
  }

  .slider-tab-title {
    display: block;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
  }

  .slider-tab.is-active {
    background: #006b97;
    position: relative;
  }

  .slider-tab.is-active .slider-tab-title {
    font-weight: 700;
  }

  .slider-tab.is-active:before {
    position: absolute;
    top: 50%;
    left: -20px;
    margin-top: -10px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid transparent;
    border-right: 10px solid #006b97;
    content: " ";
  }

  @media screen and (max-width: 1050px) {
    .slider-tab-title {
      font-size: 16px;
      line-height: 22px;
    }
  }
</style>
